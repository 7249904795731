import { Container, Alert, Form, Button, Input, InputGroup } from "reactstrap";
import React from 'react';
import PropTypes from "prop-types";

export default function SearchInput(props) {

  
  const handleChange = (e) => {
    props.changeHandler(e.target.value);
  };

  return (
    <Input               
    type="text"
    name="searchquery"
    id="searchquery"
    onChange={handleChange}
    onBlur={handleChange}
  />

  )

};