import React from 'react';

import Footer from '../components/Footer';

import { Container, Row, Col } from 'reactstrap';


import Layout from '../components/layout';
import SEO from '../components/seo';

import ScrollingEff from '../components/ScrollingEff';
import { graphql } from 'gatsby';
import SearchForm from '../components/Search/SearchForm';



class Search extends React.Component {
  
  render() {

    return (
      <Layout>
      <SEO title="Search" />
      <ScrollingEff />
      <div>
        <Container fluid className="w-80 content-page block">
          
          <Row>
            <Col>
              <h1>Search</h1>
              <SearchForm />
            </Col>
          </Row>
        </Container>

        <Footer />
      </div>
      </Layout>

    )
  }
}
export default Search;


export const pageQuery = graphql`
    query SearchIndexQuery {
  localSearchPages {
    index
    store
  }
}`