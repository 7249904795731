import React from 'react';
import Link from '../../net/Link';
import {formatDate} from '../../utils/dates';

export default function SearchResults({ results, emptyQuery }) {

  let resultString;
  if (emptyQuery) {
    resultString = "Enter your search term in the field above."
  } else {
    resultString = "No results for this query."
  }

  let nodes = results.map(result => {
    
    return (
      <li key={result.url} class="mt-2">
        <div>
          <h2><Link to={result.url}>{result.title}</Link><span class="date">{formatDate(result.date)}</span></h2>
        </div>
        <p class="abstract">{result.abstract}</p>
        <div></div>
      </li>
    )
  });
let hasResults = nodes.length > 0;

    return (
      <div class="mt-4">
      { 
        hasResults ?
        <ol class="searchresults">
        {nodes}
        </ol>
        :
        <p><em>{resultString}</em></p>

      }
      </div>
    )
  
}

