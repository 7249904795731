import SearchInput from "./SearchInput";
import SearchResults from './SearchResults';
import React, { useState } from 'react';


export default function SearchForm() {

  const [results, setResults] = useState([]);
  const [queryEmpty, setQueryEmpty] = useState(true);

  const handleSearchInput = (value) => {
    if (value === "") {
      setQueryEmpty(true);
      setResults([]);
    } else {
      setQueryEmpty(false);
      if (value.length > 2) {
        performSearch(value);
      } else {
        setResults([]);
      }
      
    }
    
  }

  const performSearch = (query) => {
    var index = window.__FLEXSEARCH__.en.index;
    var store = window.__FLEXSEARCH__.en.store;
    //let query="agile";
    var results = []
    Object.keys(index).forEach(idx => {
      results.push(...index[idx].values.search(query))
    })

    results = Array.from(new Set(results))
    var nodes = store
      .filter(node => {
        if (results.includes(node.id)) {
          //only show results from the articles and LBF areas
          if (node.node.url.startsWith('/articles/') || node.node.url.startsWith('/lets-be-fwends/'))
          return node;
        } else {
          return null;
        }
      } 
      )
      .map(node => node.node)

    setResults(nodes);

    

  }

  return (
    <>
    <SearchInput changeHandler={handleSearchInput} />
    <SearchResults results={results} emptyQuery={queryEmpty}/>
    </>
  )
};